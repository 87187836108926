import s from "./ItemOrder.module.css";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";


interface ObjectModal {
    id: number;
    count:number;
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount?: number | null,
    description?: string | null;
    discountprocent?: number | null;
    small?: boolean;
    isSlider?: boolean;
    isSimiliar?: boolean;
    name: string;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}


const ItemOrder = (props: ItemCatigories) => {
    const {id, name, img, description, price, discount, small, isSlider, handleClickRes,isSimiliar,objectModal,discountprocent} = props
    const setData = useSetLocationData();
    const isObject = objectModal && objectModal.filter((el)=> el.id === id).length > 0
    const { searchText } = useGetLocationData();
    const handleButtonClick = () => {
        // const params = new URLSearchParams(window.location.search);
        // // params.set('modal', `${id}`);
        // // params.set('total', `${id}`);
        // params.set("categories",'');
        // window.location.search = params.toString();
        setData("modal",id,)


    };




    return (
        <div

            className={clsx(s.item, {
            [s.smallItem]: small,
            [s.similiar]: isSimiliar,
            [s.sliderItem]: isSlider
        })} key={`${id}${name}`}>
            {/*<div onClick={()=> handleClickRes && handleClickRes(id)} className={clsx(s.icon, {*/}
            {/*    [s.isSliderIcon]: isSlider,*/}
            {/*})}>*/}

            {/*    { !isObject ?*/}
            {/*        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <path d="M7.00016 1.58337V12.4167M12.4168 7.00004H1.5835" stroke="#F85D42" strokeWidth="2"*/}
            {/*                  strokeLinecap="round" strokeLinejoin="round"/>*/}
            {/*        </svg>*/}
            {/*        :*/}

            {/*        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <path d="M14.0667 3.35002H11.1334V2.69169C11.1334 2.0618 10.8832 1.45771 10.4378 1.01231C9.99236 0.56691 9.38827 0.316687 8.75838 0.316687H7.24172C6.61183 0.316687 6.00774 0.56691 5.56234 1.01231C5.11694 1.45771 4.86672 2.0618 4.86672 2.69169V3.35002H1.93338C1.70574 3.35002 1.48742 3.44045 1.32645 3.60142C1.16548 3.76239 1.07505 3.98071 1.07505 4.20835C1.07505 4.436 1.16548 4.65432 1.32645 4.81529C1.48742 4.97626 1.70574 5.06669 1.93338 5.06669H2.59172V13.3084C2.59172 13.9382 2.84194 14.5423 3.28734 14.9877C3.73274 15.4331 4.33683 15.6834 4.96672 15.6834H11.0334C11.6633 15.6834 12.2674 15.4331 12.7128 14.9877C13.1582 14.5423 13.4084 13.9382 13.4084 13.3084V5.06669H14.0667C14.2944 5.06669 14.5127 4.97626 14.6737 4.81529C14.8346 4.65432 14.925 4.436 14.925 4.20835C14.925 3.98071 14.8346 3.76239 14.6737 3.60142C14.5127 3.44045 14.2944 3.35002 14.0667 3.35002ZM6.58338 2.69169C6.58338 2.51709 6.65274 2.34964 6.7762 2.22618C6.89967 2.10271 7.06711 2.03335 7.24172 2.03335H8.75838C8.93298 2.03335 9.10043 2.10271 9.2239 2.22618C9.34736 2.34964 9.41672 2.51709 9.41672 2.69169V3.35002H6.58338V2.69169ZM11.6917 13.3084C11.6917 13.483 11.6224 13.6504 11.4989 13.7739C11.3754 13.8973 11.208 13.9667 11.0334 13.9667H4.96672C4.79211 13.9667 4.62467 13.8973 4.5012 13.7739C4.37774 13.6504 4.30838 13.483 4.30838 13.3084V5.06669H11.6917V13.3084Z" fill="#F85D42" stroke="#F85D42" strokeWidth="0.2"/>*/}
            {/*        </svg>*/}

            {/*    }*/}
            {/*</div>*/}
            <div onClick={handleButtonClick}>
            <div className={clsx(s.menuButtonCategory, {
                [s.isSlider]: isSlider,
            })}>


                {img
                    ? <img className={s.img} src={img}/>
                    :<img className={s.img} src={"https://edabirja.ru/img/no-photos.png"}/>

                }
            </div>
            <div className={s.name}>{name}</div>
            <div className={s.price}>

                {(discount || price === discountprocent) ?
                    <div className={s.discount}><div> <span className={s.priceDisc}>{price} ₽</span> <span className={s.discPersent}>-{discountprocent}%</span> </div> <span className={s.disc}>{discount} ₽</span></div> :
                    <span> {price} ₽</span>

                }
            </div>
            </div>
        </div>
    )
}

export default ItemOrder