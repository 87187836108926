import Action1 from "../../img/action1.png"
import Action2 from "../../img/action2.png"
import Action3 from "../../img/action3.png"
import s from "./Actions.module.css"
import {SearchForm} from "../../components/SearchForm";
import React, {useEffect, useState} from "react";
import {AXI} from "../../libs/axi";
import {useNavigate} from "react-router-dom";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";

interface ItemCatigories {
    id: number;
    img: string;
    description: string | null;
    name: string;
    external_link: string | null;
    external: boolean;
}

interface ObjectModal {
    id: number;
    count:number;
}

export const Actions = () => {

    const [activeDrink, setActiveDrink] = useState(1)
    const navigate = useNavigate();
    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])

    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);
    const setData = useSetLocationData();


    const handleClickRes = (id:number) => {
        const isObject = objectModal.filter((el)=> el.id === id).length > 0

        if(isObject){
            setData("searchText",id+1)
            setObjectModal(prevState => {     const updatedState = prevState.map(item =>
                item.id === id ? {...item, count: 0} : item
            );
                return updatedState.filter(item => item.count > 0);
            })
        } else {
            setData("searchText",id)
            setObjectModal(prevState =>
                [...prevState,{ id:id, count: 1 }]
            )}
    };
    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));

        if (storedData) {
            setActiveDrink(storedData);
        } else {

            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {

        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])

    useEffect(() => {
        fetch(`${AXI}/api/saleactions`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {
                    setArrCat(data);
                },
            )

    }, [])
    const handleLinkClick = (id:number,external:boolean,external_link:string | null ) => {
        if (external && external_link){
            window.open(external_link, '_blank');
        } else {

        navigate(`/actioncat/${id}`);
        }
    };

    return <Layout> <div className={s.wrapper}>
        <SearchForm active={activeDrink} handleClickRes={handleClickRes} objectModal={objectModal} />
        <div className={s.title}>Акции</div>
        {arrCat.length > 0 && arrCat.map((el) =>
            <img onClick={() => handleLinkClick(el.id, el.external, el.external_link)} src={el.img} alt={el.name} key={el.id}/>
        )}


    </div></Layout>
}