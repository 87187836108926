import React, { FC, useState, useEffect, useCallback, useRef } from "react";

import eatPng from "../../../img/categoriesIconEat.png";
import drinkPng from "../../../img/categoriesIconDrink.png";
import s from "./SimilarProduct.module.css";
import ItemOrder from "../../../components/ItemOrder";
import { AXI } from "../../../libs/axi";


interface ItemCatigories {
    id: number;
    img: string;
    price: number;
    discount: number | null;
    description: string | null;
    discountprocent: number | null;
    name: string;
}

interface ObjectModal {
    id: number;
    count: number;
}

interface Item {
    id?: number | null;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
}

const SimilarProduct: FC<Item> = ({ id: idItem, objectModal, handleClickRes }) => {
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([]);
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [idIt, setIdIt] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const observer = useRef<IntersectionObserver | null>(null);
    const lastItemRef = useCallback((node: HTMLDivElement | null) => {
        if (fetching || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setFetching(true);
            }
        });
        if (node) observer.current.observe(node);
    }, [fetching, hasMore]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${AXI}/api/items/similar?id=${idItem}&page=${page}&count=5`);
                const data = await response.json();
                if (page === 1) {
                    setArrCat(data.items);
                } else {
                    setArrCat(prev => [...prev, ...data.items]);
                }
                setHasMore(data.items.length === 5);
                setPage(prev => prev + 1);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setFetching(false);
            }
        };

        if (idItem) {
            if (idIt !== idItem) {
                setIdIt(idItem);
                setPage(1);
                setHasMore(true);
                fetchData();
            } else if (fetching) {
                fetchData();
            }
        }
    }, [idItem, fetching]);

    useEffect(() => {
        if (arrCat.length < 5 && hasMore && !fetching) {
            setFetching(true);
        }
    }, [arrCat, fetching, hasMore]);

    return (
        <div className={s.wrapper}>
            <div className={s.title}>Похожее</div>
            {arrCat.map(({ id, name, img, price, discount, discountprocent }, index) => {
                if (index === arrCat.length - 1) {
                    return (
                        <div ref={lastItemRef} className={s.itemOrdr} key={id}>
                            <ItemOrder
                                id={id}
                                name={name}
                                img={img}
                                price={price}
                                isSimiliar
                                discountprocent={discountprocent}
                                handleClickRes={handleClickRes}
                                objectModal={objectModal}
                                discount={discount}
                            />
                        </div>
                    );
                } else {
                    return (
                        <ItemOrder
                            key={`${id}${name}${index}2`}
                            id={id}
                            name={name}
                            img={img}
                            price={price}
                            discountprocent={discountprocent}
                            handleClickRes={handleClickRes}
                            objectModal={objectModal}
                            discount={discount}
                        />
                    );
                }
            })}
        </div>
    );
};

export default SimilarProduct;
